import dynamic from 'next/dynamic';
import LazyLoad from 'react-lazyload';
import Header from "./header"
import { useState } from 'react';
import Router from 'next/router';
import GoToTopIcon from '@components/icons/go-to-top-icon';
import Scroll from 'react-scroll';
import cn from 'classnames';
import GaAnalysis from "@components/layouts/ga-analysis";
import { useEffect } from 'react';
import { isMobileOnly, isTablet, isIOS } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckDeviceSettings, setForwardPath } from '@framework/app/settingsSlice';
import router from 'next/router';
import Clarity from "@components/layouts/clarity";
import { getPermissions } from '@framework/utils/get-permissions';
import { RootState } from 'src/root-redux/store';

const Footer = dynamic(() => import('./footer'));
const MobileNavigation = dynamic(() => import('./mobile-navigation'));

const SiteLayout: React.FC = ({ children }) => {
  const scroll = Scroll.animateScroll;
  const [scrollY, setScrollY] = useState(0);
  const { innerHeight } = window;
  const dispatch = useDispatch();
  const permission = getPermissions();
  const { isAuthorize } = useSelector((state: RootState) => state.authenticationSettings);

  useEffect(() => {
    dispatch(setCheckDeviceSettings({
      isMobileOnly,
      isTablet,
      isIOS
    }));
    dispatch(setForwardPath(router.asPath))
  }, []);

  document.onkeydown = function (e) {
    if (isAuthorize && permission != 'customer') return true;

    if(e.key == "F12") {
      return false;
    }

    if((e.ctrlKey || e.metaKey) && e.shiftKey && e.key == "c") {
      return false;
    }
  }

  document.addEventListener("contextmenu", function (e) {
    if (isAuthorize && permission != 'customer') return;

    e.preventDefault();
  }, false);

  window.addEventListener("scroll", (event) => {
    setScrollY(window.scrollY);
  });

  const [loader, setLoader] = useState(false);

  //@ts-ignore
  Router.onRouteChangeStart = () => {
    setLoader(true);
  };
  //@ts-ignore
  Router.onRouteChangeComplete = () => {
    dispatch(setForwardPath(router.asPath))
    setLoader(false);
  };
  //@ts-ignore
  Router.onRouteChangeError = () => {
    setLoader(false);
  };

  return (
    <div className="flex flex-col min-h-screen transition-colors duration-150 bg-gray-full">
      <GaAnalysis />
      <Clarity />
      <Header />
      <div className="body-page">
        {children}
      </div>
      <MobileNavigation />
      <LazyLoad height={200} once offset={-100}>
        <Footer />
      </LazyLoad>

      <div className={cn('fixed right-2 sm:right-6 bottom-20 sm:bottom-6 py-2 px-3 rounded-[15px] bg-accent hover:bg-accent-hover cursor-pointer z-[200] opacity-0',
        scrollY > (innerHeight / 3) ? "fade-in" : "fade-out"
      )} onClick={() => scroll.scrollToTop()}>
        <GoToTopIcon />
      </div>
    </div>
  );
};
export const getLayout = (page: React.ReactElement) => (
  <SiteLayout>{page}</SiteLayout>
);
export default SiteLayout;
